import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { LayoutGroup, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import type { ReactElement } from 'react';
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { string } from 'yup';

import BlockPageWithoutPhoneNumber from '@/components/BlockPageWithoutPhoneNumber';
import Button from '@/components/Button';
import UploadDialog from '@/components/CSV/UploadDialog';
import AddCVForm from '@/components/CVGenerator/AddCVForm';
import PaginatedQueryControls from '@/components/CVGenerator/PaginatedQueryControls';
import Collections from '@/components/Dashboard/Collections';
import DashboardControls from '@/components/Dashboard/DashboardControls';
import DashboardGridLayout from '@/components/Dashboard/GridLayout';
import LayoutButtonBase from '@/components/Dashboard/LayoutButton';
import Navigation from '@/components/Dashboard/navigation/Navigation';
import SelectModeControls from '@/components/Dashboard/SelectModeControls';
import DashboardTableLayout from '@/components/Dashboard/TableLayout';
import FileInput from '@/components/Form/Fields/FileInput';
import Text from '@/components/Text';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { DocumentsFileTypes } from '@/config/filesTypes';
import { GAActions, GACategories } from '@/config/googleAnalytics';
import { LocalStorage } from '@/config/localStorage';
import { queries } from '@/queries';
import { useDashboard } from '@/stores/useDashboardStore';
import { getBooleanParam, getNumberParams } from '@/utils/queryParams';

const CVGenerator = () => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const [csvImportDialogOpen, setCsvImportDialogOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState('');

  const [files, setFiles] = useState<File[]>([]);
  const { query } = useRouter();
  const {
    pagination,
    setSelectedContexts,
    selectedContexts,
    layout,
    setLayout,
  } = useDashboard();

  const {
    pagination: { pageIndex, pageSize },
    setParams,
  } = useDashboard();

  const { collection: collectionParam, csvImport } = router.query;
  const parsedCollection = getNumberParams(collectionParam);
  const collections = parsedCollection ? [parsedCollection] : [];
  const parsedImport = getBooleanParam(csvImport);

  const { data } = useQuery({
    ...queries.externalCandidates.list({ search, collections, pagination }),
    staleTime: 5 * 60 * 1000,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (parsedImport) {
      setCsvImportDialogOpen(true);
      router.replace({ query: { ...router.query, csvImport: undefined } });
    }
  }, [parsedImport]);

  useEffect(() => {
    const getSavedLayout = async () => {
      const savedLayout = localStorage.getItem(LocalStorage.DASHBOARD_LAYOUT);
      const validatedData = await string()
        .oneOf(['grid', 'list'])
        .nullable()
        .validate(savedLayout);
      setLayout(validatedData ?? 'grid');
    };
    getSavedLayout();
  }, []);

  useEffect(() => {
    setSelectedContexts([]);
    setParams({ page: 0, pageSize: pagination.pageSize });
  }, [JSON.stringify(collections)]);

  useEffect(() => {
    if (router.query.actions === 'open-add-candidate') {
      setModalOpen(true);
    }
    if (router.query.actions) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, actions: undefined },
      });
    }
  }, [router]);

  const candidatesCount = data?.count;
  const LayoutButton = () =>
    useMemo(
      () => (
        <LayoutButtonBase
          layout={layout}
          onChange={(newLayout) => {
            ReactGA.event({
              category: GACategories.DASHBOARD,
              action:
                newLayout === 'grid'
                  ? GAActions.SET_DASHBOARD_GRID_LAYOUT
                  : GAActions.SET_DASHBOARD_LIST_LAYOUT,
            });
            setLayout(newLayout);
            localStorage.setItem(LocalStorage.DASHBOARD_LAYOUT, newLayout);
          }}
        />
      ),
      [layout, setLayout]
    );

  return (
    <BlockPageWithoutPhoneNumber>
      <LayoutGroup>
        <motion.div className="flex flex-1 overflow-auto overflow-x-hidden">
          <div className="flex max-h-screen w-full flex-1 flex-col overflow-auto">
            <div
              data-joyride="dashboard"
              className="flex flex-1 flex-col overflow-hidden"
            >
              <div className="mb-6 flex items-center gap-4">
                <Text variant="h5" className="font-semibold">
                  All candidates ({candidatesCount})
                </Text>
                <Button
                  onClick={() => setModalOpen(true)}
                  size="sm"
                  prefixIcon={faPlus}
                  className="whitespace-nowrap"
                  data-joyride="add-candidate"
                >
                  Add candidate
                </Button>
                <UploadDialog
                  dialogProps={{
                    open: csvImportDialogOpen,
                    onOpenChange: setCsvImportDialogOpen,
                  }}
                  dialogTriggerProps={{ asChild: true }}
                >
                  <Button size="sm" variant="secondary">
                    CSV Import
                  </Button>
                </UploadDialog>
              </div>
              <div className="mb-4 flex gap-3 ">
                <DashboardControls search={search} onSearchChange={setSearch} />
                {layout === 'grid' && <LayoutButton />}
              </div>
              <Collections />
              {layout === 'list' && (
                <motion.div layout="size" className="flex justify-between ">
                  <div className="flex items-center gap-2">
                    <Text
                      variant="body-small"
                      className="min-w-20 whitespace-nowrap"
                    >
                      Page{' '}
                      <span className="font-semibold text-primary-300">
                        {pageIndex + 1}
                      </span>{' '}
                      of{' '}
                      <span className="font-semibold text-primary-300">
                        {Math.ceil((candidatesCount ?? 0) / pageSize) || 1}
                      </span>
                    </Text>
                    <div className="flex gap-2">
                      <PaginatedQueryControls
                        data={data}
                        page={pageIndex}
                        pageSize={pageSize}
                        onPageChange={(newPage) =>
                          setParams({ page: newPage, pageSize })
                        }
                        onPageSizeChange={(newPageSize) =>
                          setParams({
                            pageSize: newPageSize,
                            page: 0,
                          })
                        }
                        onPrefetch={(controlsPagination) =>
                          queryClient.prefetchQuery({
                            ...queries.cvGenerator.list({
                              search,
                              pagination: controlsPagination,
                            }),
                            staleTime: 1000 * 60 * 5,
                          })
                        }
                      />
                    </div>
                  </div>
                  <LayoutButton />
                </motion.div>
              )}
              <motion.div
                layout
                className={classNames('mb-3', {
                  'mt-3': layout === 'list' && selectedContexts.length > 0,
                })}
              >
                {selectedContexts.length > 0 && (
                  <SelectModeControls
                    onClear={() => setSelectedContexts([])}
                    selectedContexts={selectedContexts}
                  />
                )}
              </motion.div>
              {layout === 'grid' ? (
                <DashboardGridLayout
                  collections={collections}
                  onAddCV={() => setModalOpen(true)}
                  onSelect={setSelectedContexts}
                  selectedContexts={selectedContexts}
                  search={search}
                />
              ) : (
                <DashboardTableLayout
                  collections={collections}
                  search={search}
                  selectedContexts={selectedContexts}
                  onSelect={setSelectedContexts}
                />
              )}
            </div>
            {!query.setting && (
              <FileInput
                name="cv-dnd"
                className={(isDragging) => [
                  { hidden: !isDragging || modalOpen },
                ]}
                variant="primary"
                isButtonStyled
                onChange={(value) => {
                  setFiles(value as File[]);
                  setModalOpen(true);
                }}
                multiple
                accept={DocumentsFileTypes}
              />
            )}
            <Dialog
              open={modalOpen}
              onOpenChange={(open) => {
                setModalOpen(open);
                if (!open) setFiles([]);
              }}
            >
              <DialogContent className="w-full max-w-2xl">
                <DialogHeader>Add candidate</DialogHeader>
                <AddCVForm
                  defaultCollections={
                    parsedCollection ? [parsedCollection] : undefined
                  }
                  defaultValue={files}
                  onRequestClose={() => setModalOpen(false)}
                />
              </DialogContent>
            </Dialog>
          </div>
        </motion.div>
      </LayoutGroup>
    </BlockPageWithoutPhoneNumber>
  );
};

CVGenerator.getLayout = (page: ReactElement) => <Navigation>{page}</Navigation>;

export default CVGenerator;
