/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  faEdit,
  faEllipsisVertical,
  faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type FC, useCallback } from 'react';
import ReactGA from 'react-ga4';

import Button from '@/components/Button';
import Text from '@/components/Text';
import { GAActions, GACategories } from '@/config/googleAnalytics';
import { useDeleteExternalCandidates } from '@/hooks/useDeleteExternalCandidates';
import { queries } from '@/queries';
import {
  type ExternalCandidateList,
  postRetryExternalCandidate,
} from '@/services/externalCandidates';
import { dateFormatter } from '@/utils/date';
import { getNumberParams, getStringParam } from '@/utils/queryParams';

import Checkbox from '../Form/Fields/Checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import CVDownloadButton from './DownloadButton';
import GenerateCVDialog from './GenerateCVDialog';

type Props = {
  context: ExternalCandidateList;
  selected?: boolean;
  onSelect?: (context: ExternalCandidateList) => void;
  disableGenerate?: boolean;
  selectDisabled?: boolean;
};
const CVContext: FC<Props> = ({
  context,
  selected,
  onSelect,
  disableGenerate,
  selectDisabled,
}) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { query } = router;
  const parsedId = getStringParam(query.id);

  const { mutate: deleteContext } = useDeleteExternalCandidates();

  const { mutate: retry, isLoading: isRetryLoading } = useMutation({
    mutationFn: () => postRetryExternalCandidate(context.id),
    mutationKey: ['externalCandidateRetry'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.externalCandidates.list._def);
      queryClient.invalidateQueries(
        queries.externalCandidates.detail(context.id).queryKey
      );
    },
  });

  const status = (() => {
    if (context.status === 'READY')
      return (
        <Text variant="body-small" className=" text-neutral-600">
          READY
        </Text>
      );
    if (context.status === 'FAILED')
      return (
        <Text variant="body-small" className="font-semibold text-error">
          FAILED
        </Text>
      );
    return (
      <Text variant="body-small" className="text-primary-500">
        PROCESSING
      </Text>
    );
  })();

  const MainButton = useCallback(() => {
    if (context.status === 'FAILED')
      return (
        <Button
          onClick={() => {
            retry();
          }}
          prefixIcon={faRotateRight}
          isLoading={isRetryLoading}
          size="sm"
        >
          Retry
        </Button>
      );
    if (context.status === 'PROCESSING')
      return (
        <Button disabled variant="secondary" size="sm">
          Generate resume
        </Button>
      );
    if (context.cvContexts.length <= 0)
      return (
        <GenerateCVDialog
          profileId={context.id}
          dialogTriggerProps={{
            asChild: true,
            disabled: disableGenerate,
          }}
        >
          <Button variant="secondary" size="sm">
            Generate resume
          </Button>
        </GenerateCVDialog>
      );
    return (
      <Link
        href={{
          pathname: `/dashboard/generator/${context.cvContexts?.[0].id}`,
          query: {
            tabs: context.cvContexts.slice(1).map(({ id }) => id),
          },
        }}
      >
        <Button
          size="sm"
          className="w-full"
          tabIndex={-1}
          variant="secondary"
          prefixIcon={faEdit}
        >
          Edit resume
        </Button>
      </Link>
    );
  }, [context.status, context.cvContexts.length]);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <div
      key={context.id}
      className="relative flex h-full w-full flex-1 transition-opacity"
    >
      <div
        className={classNames(
          'w-full h-full absolute top-0 left-0 pointer-events-none',
          {
            'animate-pulse border-4 border-primary-400 rounded-lg':
              getNumberParams(query.context) === context.id,
          }
        )}
      />
      <div
        className={classNames(
          'w-full flex flex-col rounded-lg relative justify-between border-2 bg-neutral-100 pb-1 border-transparent',
          {
            'shadow-md shadow-primary-700 !border-primary-500':
              context.id.toString() === parsedId,
          }
        )}
      >
        <div className="flex justify-between bg-neutral-200 p-2">
          <Checkbox
            disabled={selectDisabled}
            name={context.id.toString()}
            className="!p-0"
            checked={selected}
            onChange={() => onSelect?.(context)}
          />
          <DropdownMenu>
            <DropdownMenuTrigger className="-mr-2 px-2">
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className="text-neutral-800"
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-fit">
              <DropdownMenuItem asChild>
                <Button
                  variant="unstyled"
                  size={null}
                  className="w-full !justify-start"
                  confirm="Are you sure you want to delete this resume?"
                  onClick={() => deleteContext([context.id])}
                >
                  Delete
                </Button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="p-3">
          <div>
            <div className="flex justify-between">
              <Text
                variant="body"
                className="truncate font-semibold text-black"
              >
                {context.name || 'Not specified'}
              </Text>
              {status}
            </div>

            <Text className="text-neutral-800">
              Last edit:{' '}
              {dateFormatter({
                date: context.updatedAt,
                format: { dateStyle: 'short' },
              })}
            </Text>
            <div className="w-full border-b border-neutral-300" />
            <div className="flex items-center justify-between border-neutral-600" />
          </div>
          <div className="mt-2 flex w-full flex-col gap-2 self-end">
            <MainButton />
            <CVDownloadButton
              onDownlad={() =>
                ReactGA.event({
                  category: GACategories.RESUME_DOWNLOAD,
                  action: GAActions.DOWNLOAD_QUICK,
                })
              }
              buttonProps={{
                size: 'sm',
                disabled: !context.cvContexts?.[0]?.id,
              }}
              id={context.cvContexts?.[0]?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CVContext;
