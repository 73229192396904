import { useEffect, useState } from 'react';
import type { Step } from 'react-joyride';

import { isDevelopment } from '@/config/config';
import dashboardFixture from '@/fixtures/dashboard.json';

import { useProfile } from '../useProfile';

export const useDashboardOnboarding = () => {
  const { data: profileData } = useProfile();

  const onboardingState = useState<{ run: boolean; steps: Step[] }>({
    run: false,
    steps: [
      {
        target: '[data-joyride="dashboard"]',
        title: 'Welcome to MindPal',
        content:
          'Explore our AI tools to automate your daily work with a quick overview of what MindPal can do. ',
        disableBeacon: true,
        placement: 'center',
        locale: {
          next: "Let's get started",
        },
      },
      {
        target: '#joyride-search',
        title: 'Search for Candidates',
        content:
          'Comfortably browse lists of candidates. Here you have quick access to all data you need.',
        disableBeacon: true,
      },
      {
        target: '[data-joyride="settings"]',
        title: 'Customize fields',
        content:
          'Click on the settings icon in order to customize the displayed fields to suit your needs.',
        disableBeacon: true,
        spotlightClicks: true,
        hideFooter: true,
        hideCloseButton: true,
        floaterProps: {
          styles: { arrow: { margin: '8px' } },
        },
      },
      {
        target: '[data-joyride="columns-visibility-and-ordering"]',
        title: 'Select fields to display',
        content:
          'Check the boxes you want to see and hide the ones that you don’t want to see.',
        disableBeacon: true,
      },
      {
        target: '[data-joyride="profile-link"]',
        title: 'Profiles',
        content: 'See the details of selected candidate profiles. ',
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: '[data-joyride="resume"]',
        title: 'Edit candidate’s resume',
        content: 'See how easy it is! Just click on the resume icon.',
        disableBeacon: true,
        placement: 'right',
      },
    ],
  });

  useEffect(() => {
    if (!profileData || isDevelopment) return;
    if (!profileData.dashboardOnboardingAccomplished)
      onboardingState[1]((prev) => ({ ...prev, run: true }));
  }, [profileData?.dashboardOnboardingAccomplished]);
  return { onboardingState, fixtures: dashboardFixture };
};
