import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DraggableProvided } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import type { Column, Table } from '@tanstack/react-table';
import { type FC, type PropsWithChildren } from 'react';

import Checkbox from './Form/Fields/Checkbox';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';

type Props = {
  table: Table<any>;
  offset?: number;
  triggerAsChild?: boolean;
  hiddenColumnsIds?: string[];
};
const ColumnsVisibilityAndOrdering: FC<PropsWithChildren<Props>> = ({
  table,
  offset = 0,
  children,
  triggerAsChild,
  hiddenColumnsIds = ['select'],
}) => {
  const visibilityOptions = table.getAllLeafColumns().filter(({ id }) => {
    return !hiddenColumnsIds.includes(id);
  });

  return (
    <Popover>
      <PopoverTrigger data-joyride="settings" asChild={triggerAsChild}>
        {children}
      </PopoverTrigger>
      <PopoverContent
        data-joyride="columns-visibility-and-ordering"
        className="relative"
        onFocusOutside={(e) => e.preventDefault()}
      >
        <DragDropContext
          onDragEnd={({ source, destination }) => {
            if (!destination) return;
            const reorderedArr = [...visibilityOptions.map((d) => d.id)];
            const [movedItem] = reorderedArr.splice(
              source.index - 1 + offset,
              1
            );
            reorderedArr.splice(destination.index - 1 + offset, 0, movedItem);
            table.setColumnOrder(reorderedArr);
          }}
        >
          <Droppable
            droppableId="columnOrder"
            renderClone={(provided, snapshot, rubic) => (
              <DraggableItem
                provided={provided}
                column={visibilityOptions[rubic.source.index - 1]}
              />
            )}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col"
              >
                {visibilityOptions.map((column, index) => {
                  return (
                    <Draggable
                      key={JSON.stringify(column)}
                      index={index + 1}
                      draggableId={JSON.stringify(column)}
                    >
                      {(draggableProvided) => (
                        <DraggableItem
                          provided={draggableProvided}
                          column={column}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </PopoverContent>
    </Popover>
  );
};

export default ColumnsVisibilityAndOrdering;

type DraggableItemProps = {
  provided: DraggableProvided;
  column: Column<unknown>;
};

const DraggableItem: FC<DraggableItemProps> = ({ provided, column }) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className="flex items-center justify-between gap-1 border-b border-neutral-700 py-3"
    >
      <Checkbox
        name={column.id}
        key={column.id}
        checked={column.getIsVisible()}
        onChange={column.getToggleVisibilityHandler()}
        className="!p-0"
      >
        {column.columnDef.meta?.altHeader ??
          column.columnDef.header?.toString()}
      </Checkbox>
      <FontAwesomeIcon icon={faGripVertical} />
    </div>
  );
};
