import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queries } from '@/queries';
import { deleteExternalCandidates } from '@/services/externalCandidates';
import { useDashboard } from '@/stores/useDashboardStore';

export const useDeleteExternalCandidates = () => {
  const queryClient = useQueryClient();
  const { setSelectedContexts } = useDashboard();
  return useMutation({
    mutationFn: (ids: number[]) => deleteExternalCandidates(ids),
    mutationKey: ['deleteExternalCandidate'],
    onSuccess: () => {
      queryClient.invalidateQueries(
        queries.externalCandidates.collections.queryKey
      );
      queryClient.invalidateQueries(queries.externalCandidates.list._def);
      setSelectedContexts([]);
    },
  });
};
