import { Icon } from '@iconify/react';
import classNames from 'classnames';
import type { FC } from 'react';

type Props = {
  onChange: (value: 'grid' | 'list') => void;
  layout: 'grid' | 'list';
};

const LayoutButton: FC<Props> = ({ onChange, layout }) => {
  return (
    <div className="flex h-full">
      <button
        onClick={() => onChange('list')}
        type="button"
        className={classNames(
          'rounded-l-lg border-y border-l border-neutral-600 transition-colors bg-neutral-1000 px-3 text-neutral-600',
          {
            '!bg-purple-100 !border-primary-600 !text-primary-600':
              layout === 'list',
          }
        )}
      >
        <Icon className="text-[1.5rem]" icon="material-symbols:list" />
      </button>
      <button
        onClick={() => onChange('grid')}
        type="button"
        className={classNames(
          'rounded-r-lg border border-neutral-600 transition-colors border-l-primary-600 bg-neutral-1000 px-3 text-neutral-600',
          {
            '!bg-purple-100 !border-primary-600 !text-primary-600':
              layout === 'grid',
          }
        )}
      >
        <Icon className="text-[1.312rem]" icon="ion:grid-outline" />
      </button>
    </div>
  );
};

export default LayoutButton;
