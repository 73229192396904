import { create } from 'zustand';

type ReactStyleStateSetter<T> = T | ((prev: T) => T);

type CSVImportState = {
  file?: File;
  setFile: (file: ReactStyleStateSetter<File | undefined>) => void;
  collection: { value: number; label: string } | null;
  setCollection: (collection: { value: number; label: string } | null) => void;
  reset: () => void;
};

export const useCSVImportStore = create<CSVImportState>()((set) => ({
  file: undefined,
  setFile: (newFile) => {
    set(({ file }) => {
      if (typeof newFile === 'function') {
        return { file: (newFile as Function)(file) };
      }
      return {
        file: newFile,
      };
    });
  },
  collection: null,
  setCollection: (newCollection) => {
    set(() => ({
      collection: newCollection,
    }));
  },
  reset: () => {
    set(() => ({ file: undefined, collection: null }));
  },
}));
