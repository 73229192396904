import { yupResolver } from '@hookform/resolvers/yup';
import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC, type PropsWithChildren, useState } from 'react';
import { Controller } from 'react-hook-form';
import type { InferType } from 'yup';
import { array, number, object, string } from 'yup';

import { useExternalCandidatesCollections } from '@/hooks/useExternalCandidatesCollections';
import useForm from '@/hooks/useForm';
import { queries } from '@/queries';
import {
  patchCollection,
  postExternalCandidatesCollection,
} from '@/services/externalCandidates';

import Button from '../Button';
import { Select } from '../Select';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';

type Props = {
  candidatesId: number[];
  dialogProps?: DialogProps;
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
};

const AddCandidateToCollection: FC<PropsWithChildren<Props>> = ({
  candidatesId,
  dialogContentProps,
  dialogProps,
  dialogTriggerProps,
  children,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const { control, handleSubmit, setValue } = useForm<InferType<typeof schema>>(
    {
      resolver: yupResolver(schema),
    }
  );
  const { data } = useExternalCandidatesCollections();
  const options = data?.results.map((collection) => ({
    value: collection,
    label: collection.title,
  }));
  const { mutate, isLoading } = useMutation({
    mutationFn: postExternalCandidatesCollection,
    onSuccess: (response) => {
      queryClient.invalidateQueries(
        queries.externalCandidates.collections.queryKey
      );
      setValue('collection', { label: response.title, value: response });
    },
  });
  const { mutate: addCandidateToCollection, isLoading: isCandidateAddLoading } =
    useMutation({
      mutationKey: ['addCandidateToCollection'],
      mutationFn: patchCollection,
      onSuccess: () => {
        queryClient.invalidateQueries(queries.externalCandidates.list._def);
        queryClient.invalidateQueries(
          queries.externalCandidates.collections.queryKey
        );

        Promise.all(
          candidatesId.map((id) =>
            queryClient.invalidateQueries(
              queries.externalCandidates.detail(id).queryKey
            )
          )
        );

        dialogProps?.onOpenChange?.(false);
        setDialogOpen(false);
      },
    });
  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen} {...dialogProps}>
      {children && (
        <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      )}
      <DialogContent {...dialogContentProps}>
        <DialogHeader>Add candidate to collection</DialogHeader>
        <form
          onSubmit={handleSubmit((formData) =>
            addCandidateToCollection({
              id: formData.collection.value.id,
              data: {
                candidates: [
                  ...candidatesId,
                  ...formData.collection.value.candidates.map(({ id }) => id),
                ],
              },
            })
          )}
          className="flex flex-col gap-10"
        >
          <Controller
            control={control}
            name="collection"
            render={({ field: { name, onBlur, onChange, value } }) => (
              <Select
                menuPortalTarget={document.querySelector('body')}
                isLoading={isLoading}
                labelClassName="text-neutral-1000"
                variant="transparent"
                label="Collection"
                options={options}
                name={name}
                classNames={{
                  menu: () => '!z-[9999] !pointer-events-auto',
                  menuPortal: () => '!z-[9999] !pointer-events-auto',
                }}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                isCreatable
                onCreateOption={(inputValue) => {
                  mutate({
                    title: inputValue,
                    description: '',
                  });
                }}
              />
            )}
          />
          <Button isLoading={isCandidateAddLoading} type="submit">
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCandidateToCollection;

const schema = object({
  collection: object({
    value: object({
      id: number().defined(),
      title: string().defined(),
      description: string().defined(),
      candidates: array().defined(),
    }),
    label: string().defined(),
  }).defined(),
});
