import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import type { FC } from 'react';

import DebouncedTextInputField from '../Form/Fields/DebouncedTextInputField';

type Props = {
  onSearchChange: (search: string) => void;
  search: string;
};
const DashboardControls: FC<Props> = ({ onSearchChange, search }) => {
  return (
    <motion.div layout className="flex flex-1 flex-wrap items-center gap-3">
      <DebouncedTextInputField
        prefixIcon={faSearch}
        placeholder="I am looking for..."
        value={search}
        onChange={(e) => onSearchChange(e.target.value)}
        canHaveErrorMessage={false}
        className="flex-1"
        variant="grayOutline"
        containerId="joyride-search"
      />
    </motion.div>
  );
};

export default DashboardControls;
