import type {
  ColumnOrderState,
  Updater,
  VisibilityState,
} from '@tanstack/react-table';

export const saveVisibility = (
  visibility: VisibilityState,
  updaterOrValue: Updater<VisibilityState>,
  localStorageKey: string
) => {
  const getCurrentVisibility = () => {
    if (typeof updaterOrValue === 'function') {
      return updaterOrValue(visibility);
    }
    return updaterOrValue;
  };
  localStorage.setItem(localStorageKey, JSON.stringify(getCurrentVisibility()));
};

export const getSavedVisibility = (localStorageKey: string) => {
  const savedData = localStorage.getItem(localStorageKey);
  return savedData ? JSON.parse(savedData) : {};
};

export const saveColumnOrder = (
  visibility: ColumnOrderState,
  updaterOrValue: Updater<ColumnOrderState>,
  localStorageKey: string
) => {
  const getCurrentOrder = () => {
    if (typeof updaterOrValue === 'function') {
      return updaterOrValue(visibility);
    }
    return updaterOrValue;
  };
  localStorage.setItem(localStorageKey, JSON.stringify(getCurrentOrder()));
};

export const getSavedColumnOrder = (localStorageKey: string) => {
  const savedData = localStorage.getItem(localStorageKey);
  return savedData ? JSON.parse(savedData) : [];
};
