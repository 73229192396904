import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import type { PaginationState } from '@tanstack/react-table';
import type { FC } from 'react';

import type { DjangoData } from '@/services/common';

import Button from '../Button';
import { Select } from '../Select';

type Props = {
  data?: DjangoData<unknown>;
  pageSizeList?: number[];
  onPageSizeChange: (pageSize: number) => void;
  pageSize: number;
  onPageChange: (page: number) => void;
  page: number;
  onPrefetch: (pagination: PaginationState) => void;
};

const PaginatedQueryControls: FC<Props> = ({
  data,
  onPageChange,
  page,
  pageSize,
  onPageSizeChange,
  pageSizeList = [5, 10, 20, 30, 100],
  onPrefetch,
}) => {
  const pageSizeOptions = pageSizeList.map((size) => ({
    label: size.toString(),
    value: size,
  }));
  return (
    <>
      <Select
        className="mr-1 !p-0"
        classNames={{ valueContainer: () => '!py-0', control: () => '!py-0' }}
        onChange={(option) => onPageSizeChange(option?.value ?? 20)}
        value={{
          value: pageSize,
          label: pageSize.toString(),
        }}
        canHaveErrorMessage={false}
        options={pageSizeOptions}
        isSearchable={false}
        menuPlacement="auto"
        variant="gray"
      />
      <Button
        disabled={data?.previous === null || page === 0}
        onClick={() => onPageChange(page - 1)}
        prefixIcon={faAngleLeft}
        size="sm"
        variant="secondary"
        className="w-8 !border-neutral-600 before:!w-[175%]"
        onMouseEnter={() => onPrefetch({ pageIndex: page - 1, pageSize })}
      />
      <Button
        disabled={data?.next === null}
        onClick={() => onPageChange(page + 1)}
        prefixIcon={faAngleRight}
        size="sm"
        variant="secondary"
        className="w-8 !border-neutral-600 before:!w-[175%]"
        onMouseEnter={() => onPrefetch({ pageIndex: page + 1, pageSize })}
      />
    </>
  );
};

export default PaginatedQueryControls;
