import type { SortingState } from '@tanstack/react-table';
import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';
import { string } from 'yup';
import { z } from 'zod';
import { create } from 'zustand';

import { LocalStorage } from '@/config/localStorage';
import type { ExternalCandidateList } from '@/services/externalCandidates';

type ReactStyleStateSetter<T> = T | ((prev: T) => T);

type DashboardState = {
  selectedContexts: ExternalCandidateList[];
  setSelectedContexts: (
    selectedContexts: ReactStyleStateSetter<ExternalCandidateList[]>
  ) => void;
  layout?: 'grid' | 'list';
  setLayout: (layout: 'grid' | 'list') => void;
};

const useDashboardStore = create<DashboardState>()((set) => ({
  selectedContexts: [],
  setSelectedContexts: (newSelectedContexts) => {
    set(({ selectedContexts }) => {
      if (Array.isArray(newSelectedContexts)) {
        return { selectedContexts: newSelectedContexts };
      }
      return {
        selectedContexts: newSelectedContexts(selectedContexts),
      };
    });
  },
  layout: undefined,
  setLayout: (newLayout) => set({ layout: newLayout }),
}));

const paramsSchema = z.object({
  pageSize: z.coerce.number().optional().catch(10),
  page: z.coerce.number().optional().catch(0),
  salaryOrder: z.string().optional().catch(undefined),
  targetCurrency: z
    .union([z.literal('PLN'), z.literal('EUR'), z.literal('USD')])
    .optional()
    .catch(undefined),
  targetPeriod: z
    .union([z.literal('HOUR'), z.literal('MONTH'), z.literal('YEAR')])
    .optional()
    .catch(undefined),
});

export type DashboardParams = z.infer<typeof paramsSchema>;

const getQueryParams = (query: ParsedUrlQuery) => paramsSchema.parse(query);

export const useDashboard = () => {
  const router = useRouter();
  const { page, pageSize, salaryOrder, ...params } = getQueryParams(
    router.query
  );

  const { ...store } = useDashboardStore();
  const pagination = {
    pageIndex: page ?? 0,
    pageSize: pageSize ?? 30,
  };

  const sortingState: SortingState = (() => {
    if (!salaryOrder) return [];
    if (salaryOrder.startsWith('-')) {
      return [{ desc: true, id: salaryOrder.substring(1) }];
    }
    return [{ desc: false, id: salaryOrder }];
  })();

  const setParams = (newParams: Partial<DashboardParams>) => {
    if (!router.isReady) return;
    router.replace({
      query: {
        ...router.query,
        ...newParams,
      },
    });
  };

  useEffect(() => {
    const getSavedLayout = async () => {
      const savedLayout = localStorage.getItem(LocalStorage.DASHBOARD_LAYOUT);
      const validatedData = await string()
        .oneOf(['grid', 'list'])
        .nullable()
        .validate(savedLayout);
      store.setLayout(validatedData ?? 'grid');
    };
    getSavedLayout();
  }, []);

  useEffect(() => {
    if (!store.layout) return;
    localStorage.setItem(LocalStorage.DASHBOARD_LAYOUT, store.layout);
  }, [store.layout]);

  return {
    pagination,
    setParams,
    layout: store.layout ?? 'grid',
    sortingState,
    ...params,
    ...store,
  };
};
