import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { Dialog, DialogTrigger } from '@radix-ui/react-dialog';
import type { FC, PropsWithChildren } from 'react';

import { useDashboard } from '@/stores/useDashboardStore';

import Button from '../Button';
import { Select } from '../Select';
import { DialogContent, DialogHeader } from '../ui/dialog';

type Props = {
  dialogProps?: DialogProps;
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
};

const SalaryColumnConfigurationDialog: FC<PropsWithChildren<Props>> = ({
  children,
  dialogContentProps,
  dialogProps,
  dialogTriggerProps,
}) => {
  const { targetPeriod, targetCurrency, setParams } = useDashboard();

  const periodOptions = [
    { label: 'Monthly', value: 'MONTH' },
    { label: 'Annually', value: 'YEAR' },
    { label: 'Hourly', value: 'HOUR' },
  ];

  const currencyOptions = [
    { label: 'USD', value: 'USD' },
    { label: 'EUR', value: 'EUR' },
    { label: 'PLN', value: 'PLN' },
  ];

  const currentCurrency =
    currencyOptions.find(({ value }) => value === targetCurrency ?? '') ?? null;
  const currentPeriod =
    periodOptions.find(({ value }) => value === targetPeriod ?? '') ?? null;

  const onReset = () => {
    setParams({ targetCurrency: undefined, targetPeriod: undefined });
  };
  return (
    <Dialog {...dialogProps}>
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent {...dialogContentProps}>
        <DialogHeader>Salary settings</DialogHeader>
        <div className="flex gap-8">
          <div className="flex-1">
            <Select
              isClearable
              variant="light"
              options={currencyOptions}
              menuPortalTarget={document.querySelector('body')}
              classNames={{
                menu: () => '!z-[9999] !pointer-events-auto',
                menuPortal: () => '!z-[9999] !pointer-events-auto',
              }}
              value={currentCurrency ?? null}
              placeholder={currentPeriod ? 'PLN (default)' : undefined}
              onChange={(newValue) =>
                setParams({
                  targetCurrency:
                    (newValue?.value as 'PLN' | 'USD' | 'EUR') ?? undefined,
                })
              }
            />
          </div>
          <div className="flex-1">
            <Select
              isClearable
              variant="light"
              options={periodOptions}
              menuPortalTarget={document.querySelector('body')}
              classNames={{
                menu: () => '!z-[9999] !pointer-events-auto',
                menuPortal: () => '!z-[9999] !pointer-events-auto',
              }}
              value={currentPeriod ?? null}
              placeholder={currentCurrency ? 'Monthly (default)' : undefined}
              onChange={(newValue) =>
                setParams({
                  targetPeriod:
                    (newValue?.value as 'MONTH' | 'YEAR' | 'HOUR') ?? undefined,
                })
              }
            />
          </div>
        </div>
        <Button
          variant="secondary"
          className="w-min whitespace-nowrap"
          onClick={onReset}
        >
          Restore default
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default SalaryColumnConfigurationDialog;
