import type { ChangeEvent, ComponentProps, FC } from 'react';
import { useEffect, useState } from 'react';

import { useDebounce } from '@/hooks/useDebounce';

import TextInputField from './TextInputField';

const DebouncedTextInputField: FC<ComponentProps<typeof TextInputField>> = ({
  onChange,
  value: defaultValue,
  ...props
}) => {
  const [value, setValue] = useState<ChangeEvent<HTMLInputElement>>();
  const debouncedValue = useDebounce(value);

  useEffect(() => {
    if (!debouncedValue) return;
    onChange?.(debouncedValue);
  }, [debouncedValue]);

  return (
    <TextInputField
      {...props}
      value={value?.target.value ?? defaultValue ?? ''}
      onChange={setValue}
    />
  );
};

export default DebouncedTextInputField;
