import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@iconify/react';
import { Popover, PopoverTrigger } from '@mindpal-co/mindpal-ui';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import type { FC } from 'react';

import { useDeleteExternalCandidates } from '@/hooks/useDeleteExternalCandidates';
import { apiRoutes } from '@/services/config';
import type { DownloadCVFormat } from '@/services/cvGenerator';
import type { ExternalCandidateList } from '@/services/externalCandidates';

import Button from '../Button';
import Text from '../Text';
import { PopoverContent } from '../ui/popover';
import AddCandidateToCollection from './AddCandidateToCollectionDialog';

type Props = {
  onClear: () => void;
  selectedContexts: ExternalCandidateList[];
};
const SelectModeControls: FC<Props> = ({ onClear, selectedContexts }) => {
  const router = useRouter();
  const { mutate: deleteContexts } = useDeleteExternalCandidates();

  const selectedCVContextsIds = selectedContexts.flatMap(({ cvContexts }) =>
    cvContexts.map(({ id }) => id)
  );

  const downloadZipUrl = (type?: DownloadCVFormat) => {
    window.open(
      `${apiRoutes.cvGenerator.context.downloadZip}?${QueryString.stringify(
        { cv: selectedCVContextsIds, type },
        { arrayFormat: 'repeat' }
      )}`
    );

    onClear();
  };

  return (
    <div className="col-span-full flex w-fit items-center gap-4 rounded-3xl bg-neutral-800 px-3 py-2">
      <div className="flex items-center gap-2">
        <Icon
          onClick={() => {
            onClear();
          }}
          icon="ph:x-bold"
          className="cursor-pointer text-caption"
        />
        <Text
          variant="body-small"
          className="w-24 font-semibold"
        >{`${selectedContexts.length} selected`}</Text>
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            disabled={
              selectedContexts.length <= 0 || selectedCVContextsIds.length <= 0
            }
            variant="unstyled"
            size={null}
            prefixIcon="material-symbols:download"
          >
            Download
          </Button>
        </PopoverTrigger>

        <PopoverContent className="flex w-fit flex-col gap-2 rounded-lg bg-neutral-700 p-2">
          <Button
            variant="unstyled"
            className="w-full  !justify-start self-start "
            size={null}
            onClick={() => downloadZipUrl('PDF')}
          >
            Save as .PDF
          </Button>
          <Button
            className="w-full !justify-start self-start"
            variant="unstyled"
            size={null}
            onClick={() => downloadZipUrl('DOCX')}
          >
            Save as .docx
          </Button>
        </PopoverContent>
      </Popover>
      <Button
        disabled={
          selectedContexts.length <= 0 || selectedCVContextsIds.length <= 0
        }
        variant="unstyled"
        size={null}
        prefixIcon={faEdit}
        onClick={() => {
          router.push({
            pathname: `/dashboard/generator/${selectedCVContextsIds?.[0]}`,
            query: {
              tabs: selectedCVContextsIds?.slice(1),
            },
          });
        }}
      >
        Edit
      </Button>
      <Button
        disabled={selectedContexts.length <= 0}
        onClick={() => deleteContexts(selectedContexts.map(({ id }) => id))}
        variant="unstyled"
        confirm={`Are you sure you want to delete selected resumes: ${selectedContexts
          .map(({ name }) => name)
          .join(', ')} `}
        size={null}
        prefixIcon={faTrash}
      >
        Delete
      </Button>
      <AddCandidateToCollection
        candidatesId={selectedContexts.map(({ id }) => id)}
      >
        <Button variant="unstyled" size={null} prefixIcon={faPlus}>
          Add to collection
        </Button>
      </AddCandidateToCollection>
    </div>
  );
};

export default SelectModeControls;
