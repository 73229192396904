import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queries } from '@/queries';
import { patchExternalCandidate } from '@/services/externalCandidates';

export const usePatchExternalCandidate = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['patchExternalCandidate'],
    mutationFn: patchExternalCandidate,
    onSuccess: (_res, variables) => {
      queryClient.invalidateQueries(
        queries.externalCandidates.detail(variables.id).queryKey
      );
      queryClient.invalidateQueries(queries.externalCandidates.list._def);
      if (variables.data.collections)
        queryClient.invalidateQueries(
          queries.externalCandidates.collections.queryKey
        );
      onSuccess?.();
    },
  });
};
