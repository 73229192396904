import { Icon } from '@iconify/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { DocumentsFileTypes } from '@/config/filesTypes';
import { queries } from '@/queries';
import type { ExternalCandidateList } from '@/services/externalCandidates';

import Button, { radialGradientBackgroundTransition } from '../Button';
import CVContext from '../CVGenerator/CVContext';
import { getExtensionsString } from '../Form/Fields/FileInput';
import Text from '../Text';

type Props = {
  onAddCV: () => void;
  onSelect: Dispatch<SetStateAction<ExternalCandidateList[]>>;
  selectedContexts: ExternalCandidateList[];
  search: string;
  collections: number[];
};
const DashboardGridLayout: FC<Props> = ({
  onAddCV,
  onSelect,
  selectedContexts,
  search,
  collections,
}) => {
  const { ref, inView } = useInView();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      ...queries.externalCandidates.list({ search, collections }),
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length : undefined,
      refetchInterval: (queryData) =>
        queryData?.pages
          .flatMap(({ results }) => results)
          .some(({ status }) => status === 'PROCESSING')
          ? 1000 * 5
          : false,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
    });

  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView, data]);
  return (
    <motion.div
      layout
      className="grid grid-cols-[repeat(auto-fill,minmax(14rem,1fr))] flex-col gap-3 overflow-auto pb-3 "
    >
      <motion.button
        layout
        type="button"
        onClick={() => onAddCV()}
        className={classNames(
          `relative flex ${radialGradientBackgroundTransition(
            'before:bg-primary-500/10 before:w-[135%]'
          )} flex-col items-center justify-center rounded-[8px] bg-dashed-border-add-cv p-5`,
          {
            'min-h-[14rem]': (data?.pages?.[0].count ?? 0) <= 0,
          }
        )}
      >
        <Icon icon="mdi:resume" className="text-5xl" />
        <Text className="mt-2" variant="h4">
          Add candidate
        </Text>
        <Text
          variant="body-caption"
          className="mt-4 whitespace-nowrap text-center"
        >
          <span className="font-semibold text-primary-500">Upload files</span>{' '}
          or drag and drop here
          <br />
          We accept {getExtensionsString(DocumentsFileTypes)} files
        </Text>
      </motion.button>
      <AnimatePresence>
        {data?.pages?.map((page) =>
          page.results.map((context) => (
            <motion.div
              key={context.id}
              initial={{ opacity: 0, y: -100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -100 }}
              layout
            >
              <CVContext
                selectDisabled={context.status !== 'READY'}
                disableGenerate={selectedContexts.length > 0}
                context={context}
                onSelect={(selectedContext) =>
                  onSelect((prev) =>
                    prev
                      .map(({ id: prevId }) => prevId)
                      .includes(selectedContext.id)
                      ? prev.filter(
                          ({ id: filterId }) => filterId !== selectedContext.id
                        )
                      : [...prev, selectedContext]
                  )
                }
                selected={selectedContexts
                  .map(({ id }) => id)
                  .includes(context.id)}
              />
            </motion.div>
          ))
        )}
      </AnimatePresence>
      {hasNextPage && (
        <Button
          ref={ref}
          onClick={() => fetchNextPage()}
          variant="secondary"
          isLoading={isFetchingNextPage}
        >
          Load more
        </Button>
      )}
    </motion.div>
  );
};

export default DashboardGridLayout;
